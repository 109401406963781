.sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 0;
  width: 4%;
  background-color: $bg-color;
  position: fixed;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;

  #monograma {
    width: 80%;
    margin: 1rem;
  }

  a {
    display: block;
    color: #f5f5f5;
    padding: 1rem;
    margin-top: 0.5rem;
    text-decoration: none;
    transition: 0.6s;
  }

  a.active {
    background-color: $secundary-color;
    color: $bg-color;
    border-radius: 8px;
  }

  a:hover:not(.active) {
    background-color: $secundary-color;
    opacity: 0.9;
    color: $bg-color;
    border-radius: 8px;
  }

  button {
    display: block;
    background: transparent;
    padding: 1rem;
    margin-top: 0.5rem;
    margin-left: 0 !important;
    text-decoration: none;
    transition: 0.6s;
    border: 0px;
  }

  button:hover:not(.active) {
    opacity: 0.3;
    border-radius: 8px;
  }
}

#config {
  display: none;
}

@media screen and (max-width: 700px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
    flex-direction: row;
    margin-left: 0 !important;
    margin-bottom: 0.5rem !important;

    a {
      float: left;
      text-align: center;
      float: none;
      margin: 0 1rem;
    }

    #monograma {
      width: auto;
      height: 10vh;
      margin: 0.5rem 2rem;
    }

    a {
      text-align: center;
      float: none;
      margin: 0 1rem;
    }
  }
  div.content {
    margin-left: 0;
  }

  #config {
    display: block;
    position: relative;
    height: 5rem;

    button {
      float: right;
      height: 3.5rem;
      width: 3.5rem;
      margin: 0, 0, 0rem, 1rem;
      padding: 0;

      h5 {
        display: none;
      }

      img {
        height: 3rem;
        width: 3rem;
        border-radius: 50%;
      }
    }
  }
}
