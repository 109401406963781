.body-content .search-options {
    .top-search input[type="text"],
    input:not([class]) {
        width: 65%;
        background-color: hsl(0, 0%, 100%);
        border-color: hsl(0, 0%, 80%);
        border-radius: 20px;
        border-style: solid;
        border-width: 1px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        min-height: 38px;
        outline: 0 !important;
        padding-left: 10px;
    }

    .top-search .basic-select {
        width: 30%;

        + .basic-select {
            margin-left: 1rem;
        }

        .select__control {
            border-radius: 20px;
        }
    }

    .advanced-search {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        /* margin-left: 3rem;
       margin-bottom: 3rem; */
        margin: 0 0 3rem 3rem;

        .MuiTextField-root {
            width: 40%;

            label {
                color: black;
            }

            + .MuiTextField-root {
                margin-left: 4rem;
            }

            + .basic-select,
            .basic-select + .basic-select {
                margin-left: 4rem;
            }
        }

        .basic-select {
            width: 40%;
        }

        .select__control,
        .select__control .select__placeholder {
            color: black;
            background-color: transparent;
        }
    }
}

@media (max-width: 700px) {
    .body-content .search-options {
        .advanced-search {
            margin: 0 0 3rem 0;
        }
        .top-search button {
            width: 30%;
            max-height: 48px;
            font-size: 0.8rem;
            font-weight: bold;
            margin-left: 1rem;
        }
    }
}
