.settings-header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.settings-content {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    background: #fff;
    border-radius: 0 10px 10px 10px;
}