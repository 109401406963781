.profile-box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;

    img {
        max-width: 60%;
        box-sizing: border-box;
        object-fit: cover;
        border-radius: 30px;
        margin-bottom: 1rem;
    }

    .about {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        margin-left: 0.5rem;

        h4 {
            font-size: 2rem;
            color: $bg-color;
            font-weight: 800;
            margin: 0;
        }

        .container {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            margin-top: 1rem;
        }

        p {
            color: #808080;
            font-size: 1.3rem;
            margin: 0 0 0 0.5rem;
        }

        button {
            height: 2.6rem;
            width: 55% !important;
            border-radius: 4px;
            border: 0px;
            background: #deeff9;
            color: #478fc8;
            font-size: 1.2rem;
            font-weight: bold;
            transition: 0.2s;
            margin: 0.7rem 0 !important;
        }

        button:hover {
            opacity: 0.7;
        }
    }

    hr {
        width: 80%;
        margin-top: 2rem !important;
        margin: 0 2rem;
        border-top: 1px dashed #c8c8d3;
    }

    .button {
        height: 1.8rem;
        width: 20%;
        border-radius: 4px;
        border: 0px;
        background: #fddde2;
        color: #f24960;
        font-size: 0.9rem;
        font-weight: bold;
        transition: 0.2s;
    }

    .button:hover {
        opacity: 0.5;
    }
}

.my-profile {
    display: flex;
    flex-direction: row;
    margin-top: 2rem;
    margin-left: 1rem;
    width: 100%;

    .container {
        display: flex;
        flex-direction: column;
        width: 100%;

        h3 {
            font-weight: 450;
            font-size: 1.2rem;
        }

        h4 {
            color: $grey;
            font-size: 1rem;
        }
    }
}

@media (max-width: 700px) {
    .profile-box {
        justify-content: center;

        .about button {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        img {
            max-width: 50%;
            max-height: 100%;
            box-sizing: border-box;
            object-fit: cover;
            border-radius: 30px;
        }
    }

    .about {
        margin-left: 0;
    }

    .my-profile button {
        margin: 0 !important;
        margin-left: 1rem !important;
        padding: 0rem !important;
        background: #fddde2 !important;
        color: #f24960 !important;
    }
}
