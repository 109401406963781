#title {
    color: $secundary-color;
    font-weight: bold;
    font-size: x-large;
}

#page-description {
    color: $secundary-color;
    font-weight: 400;
    font-size: larger;

    b {
        color: $bg-color;
    }
}

.body-content2 {
    background-color: #f3acad;
    padding-top: 1rem;
    padding-left: 6%;
    padding-right: 3%;
}

.modal-confirmation form {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    margin: 0;
    padding: 0;
    border: 0;

    button:hover {
        opacity: 0.5;
    }
    button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30%;
        height: 50%;
        border-radius: 15px !important;
        border: 0;
        padding: 1rem !important;
        background: #7f43f5;
        color: #fff;
        font-size: 1.2rem;
        font-weight: 700;
        transition: 0.2s;
        margin: 5rem 0;
    }
}
