html {
  font-size: 90.5%;
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

body {
  font: 400 14px Roboto, sans-serif;
  background-color: #f0f0f5;
  -webkit-font-smoothig: antialiased;

  .body-content {
    margin-top: 1rem;
    margin-left: 6%;
    margin-right: 3%;
  }

  #page-title {
    color: $bg-color;
    font-weight: bold;
  }
}

svg {
  vertical-align: middle !important;
}

.btn-custom {
  background: #7f43f5 !important;
  color: #fff !important;
}

.btn-custom:hover {
  background: #c8c8d3 !important;
}

.btn-custom:disabled {
  background: #c8c8d3 !important;
}

.dropdown-toggle::after {
  display: none !important;
}

@media (max-width: 700px) {
  body .body-content {
    margin-left: 3%;
    margin-right: 3%;

    .overflow {
      display: flex;
      flex-direction: column;
      position: relative;
      /* position: fixed; */
      overflow: auto;
    }
  }
}
