.credit-card {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    background: white;
    border-radius: 20px;

    h2 {
        font-weight: bold;
        margin-left: 2rem;
        margin-top: 1rem;
    }

    .utils {
        margin-left: 2rem;
        margin-right: 2rem;

        input[type="text"],
        .typefile {
            align-items: center;
            width: 50%;
            height: 2.5rem;
            box-sizing: border-box;
            border: 1px solid $grey;
            border-radius: 10px;
            font-size: 16px;
            background-color: transparent;
            background-position: 10px 10px;
            background-repeat: no-repeat;
            padding: 5px 10px 8px 10px;
        }

        textarea {
            align-items: center;
            width: 90%;
            min-height: 5rem;
            box-sizing: border-box;
            border: 1px solid $grey;
            border-radius: 10px;
            font-size: 16px;
            background-color: transparent;
            background-position: 10px 10px;
            background-repeat: no-repeat;
            padding: 5px 10px 8px 10px;
        }

        .basic-select {
            width: 90%;
        }

        h4 {
            margin: 1rem 0;
        }

        .MuiFormControl-root input {
            font-size: 1.2rem !important;
            border-radius: 10px;
        }
    }

    .modal {
        display: none;
        position: fixed;
        z-index: 2;
        padding-top: 150px;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        background-color: #0000001a;

        .modal-content {
            position: relative;
            background-color: #fefefe;
            border-radius: 20px;
            margin: auto;
            padding: 0;
            width: 50%;
            animation-name: animatetop;
            animation-duration: 0.5s;

            .deletebtn {
                background-color: gray;
                cursor: pointer;
            }
        }

        .modal-header {
            padding: 40px 50px;
            background-color: $bg-color;
            color: white;
            border-radius: 20px 20px 0 0;
            text-align: center;

            h2 {
                font-weight: 300;
            }

            .modal-confirmation form {
                display: flex;
                justify-content: space-evenly;
                align-items: center;
                flex-direction: row;
                margin: 0;
                padding: 0;
                border: 0;

                button {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 30%;
                    border-radius: 15px !important;
                    border: 0px;
                    padding: 1rem !important;
                    background: $bg-color;
                    color: #fff;
                    font-size: 1rem;
                    font-weight: bold;
                    transition: 0.2s;
                    margin: 3rem 0;
                }

                button:hover {
                    opacity: 0.5;
                }
            }
        }
    }
}

@media (max-width: 700px) {
    .credit-card {
        align-items: center;
        justify-content: center;
        margin: 0;
        padding: 1rem;
        width: 100%;

        button {
            margin: 0;
            width: 80%;
        }

        h1 {
            margin: 0;
        }

        .utils textarea {
            width: 90%;
        }
    }
}

@media (max-width: 500px) {
    .credit-card {
        .modal {
            padding-top: 60px;
            .modal-content {
                width: 90%;
            }
        }

        .utils {
            margin: 0;
        }
    }
}
