.box-content {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 15%;
    background-color: white;
    padding: 1rem;
    border-radius: 8px;
    text-align: center;
    margin: 0.5rem;

    h1 {
        font-size: 1.7rem;
    }

    h2 {
        font-size: 1.1rem;
        font-weight: bold;
        color: $grey;
    }

    a {
        text-decoration: none;
    }

    a h3 {
        font-size: 1.1rem;
        margin-bottom: 0;
    }

    button {
        color: $secundary-color;
        background-color: $bg-color;
        padding: 0.3rem 1rem;
        border-radius: 1rem;
        border: 0;
        transition: 0.5s;
        font-weight: bold;
        border: 1px solid $secundary-color;
    }

    button:hover {
        color: $bg-color;
        background-color: $secundary-color;
        border: 1px solid $bg-color;
    }
}

@media (max-width: 700px) {
    .box-content {
        width: 48%;
        margin: 0.1rem;
    }
}
