.body-content {
    #table-title {
        font-weight: bold;
        color: $bg-color;
    }

    .shortcut-filter {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: flex-start;
    }
}
@media (max-width: 700px) {
    .body-content .shortcut-filter {
        width: 100%;
        height: auto;
        position: relative;
        flex-wrap: nowrap;
        overflow: auto;
    }
}

.UserLogIn {
    // background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90vw;
    height: 80vh;
    margin: auto;

    a {
        padding: 20px 40px;
        background-color: blueviolet;
        color: white;
        font-weight: bold;
        border: none;
        border-radius: 20px;
        text-decoration: none;

        &:hover{
            opacity: 0.8;
            cursor: pointer;
        }
        &:active{
            opacity: 0.3;
        }
    }

    .reload {
        color: blueviolet;
        font-weight: bold;

        &:hover {
            text-decoration: underline;
            cursor: pointer;
        }
    }
    
}
