.ticket-success {
  height: 85vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  padding-top: 5rem;

  h3 {
    color: #7f43f5;
    font-weight: bold;
    font-size: 3rem;
  }

  p {
    margin-top: 2rem;
    font-size: 1rem;
  }
}