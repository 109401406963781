.single-ticket {
    display: flex;
    flex-direction: row;
    justify-content: "space-between";

    h5 p {
        color: #7e7f81;
    }

    .ticket-info {
        display: flex;
        flex-direction: row;
        justify-content: "space-between";
        width: 100%;
    }
}
.ticket-comments {
    h5 + p {
        color: #7e7f81;
    }

    textarea {
        align-items: center;
        width: 100%;
        min-height: 5rem;
        box-sizing: border-box;
        border: 1px solid $grey;
        border-radius: 10px;
        font-size: 16px;
        background-color: transparent;
        background-position: 10px 10px;
        background-repeat: no-repeat;
        padding: 5px 10px 8px 10px;
    }

    .personal-comment {
        background-color: $secundary-color;
        padding: 1rem;
        margin-bottom: 1rem;
    }

    .user-comment {
        background-color: #e6e6e6;
        padding: 1rem;
        margin-bottom: 1rem;
    }

    .agent-comment {
        background-color: $bg-comment;
        padding: 1rem;
        margin-bottom: 1rem;
    }
}

@media (max-width: 700px) {
    .single-ticket {
        flex-direction: column;

        .ticket-info {
            flex-direction: column;
        }
    }
}
