header {
    display: flex !important;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    width: 96%;
    height: 5rem;
    padding: 0 3rem 0 3rem;
    // margin-bottom: 1rem;
    margin-left: 4%;
    background-color: white;

    .upper {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 40% !important;
    }
}

@media (max-width: 700px) {
    header {
        display: none !important;
    }
}
