.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: $secundary-color;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 25px;

  a {
    text-decoration: none;
  }

  h1 {
    font-size: 1.3rem;
    margin-left: 2rem;
    margin-bottom: 2rem;
    margin-top: 1rem;
    color: black;
    font-weight: 500;
  }
  .closebtn {
    position: absolute;
    right: -30px;
    font-size: 36px;
    margin-right: 50px;
    color: $bg-color !important;
    cursor: pointer;
  }
}

header .icons {
  border-left: dotted 1px #909090;
  border-right: dotted 1px #909090;
  padding: 0 1rem 0 1rem;

  h5 {
    color: #909090;
    margin: 0;
  }

  #user-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: transparent;
    border: 0;
  }

  #profile-image {
    margin-right: 1rem;
    object-fit: cover;
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 50%;
  }
}

@media screen and (max-height: 700px) {
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
    
    a {
      font-size: 18px;
    }
  }
}
